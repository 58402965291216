<script>
import vueDropzone from 'dropzone-vue3'

export default {
  name: "email-attachments-widget",
  components: { vueDropzone },
  props: {
    maxsize: Number,
    accepted: String,
    url: String,
    savedFiles: {
      type: Array,
      default() {
        return [];
      }
    },
    inputName: String,
  },
  data() {
    return {
      files: this.savedFiles,
      visible: false,
      dropzoneOptions: {
        url: this.url,
        timeout: 300000,
        thumbnailWidth: 150,
        maxFilesize: this.maxsize,
        acceptedFiles: this.accepted,
        addedfile: this.fileAdded,
        dictFileTooBig: "File is too big. Max filesize: {{maxFilesize}} MB."
      }
    };
  },
  computed: {
    isVisible() {
      if(this.visible){
        this.focusElement('.drop-file');
      }
      return this.visible || this.files.length;
    }
  },
  methods: {
    isProcessingFiles() {
      const vueDropzone = this.$refs.myVueDropzone;
      if (vueDropzone) {
        const queuedFiles = vueDropzone.getQueuedFiles();
        const uploadingFiles = vueDropzone.getUploadingFiles();
        return queuedFiles.concat(uploadingFiles).length > 0;
      } else {
        return false;
      }
    },
    findFileIndex(uuid) {
      return this.files.findIndex(function (f) {
        return f.uuid === uuid;
      });
    },
    error(file, message, xhr) {
      const fileIndex = this.findFileIndex(file.upload.uuid);

      if (xhr && [400, 401].includes(xhr.status) && message.error_message) {
        this.files[fileIndex].errorMessage = message.error_message;
      } else if (xhr && xhr.status <= 500) {
        this.files[fileIndex].errorMessage = "The file couldn't be attached at this time. Please try again later.";
      } else {
        this.files[fileIndex].errorMessage = message
      }
      this.focusElement('.file-row');

      delete this.files[fileIndex].progress
    },
    fileAdded(file) {
      this.files.push({
        uuid: file.upload.uuid,
        id: file.id,
        fileName: file.name,
        fileSize: file.size,
        url: file.url,
        progress: file.upload.progress
      });
    },
    remove(uuid) {
      const file = this.$refs.myVueDropzone.getAcceptedFiles().find(function (file) {
        return file.upload.uuid === uuid;
      });
      if (file) {
        this.focusElement('.drop-file');
        this.$refs.myVueDropzone.removeFile(file);
      } else {
        this.removeFile(uuid);
      }
    },
    removedFile(file, error, xhr) {
      this.removeFile(file.upload.uuid);
    },
    removeFile(uuid) {
      const fileIndex = this.findFileIndex(uuid);

      this.files.splice(fileIndex, 1);
    },
    sending: function sending(file, xhr, formData) {
      formData.append('uuid', file.upload.uuid);
    },
    success(file, response) {
      const fileIndex = this.findFileIndex(file.upload.uuid);

      this.files[fileIndex].id = response.id
      this.files[fileIndex].url = response.url
      this.focusElement('.file-row');
      this.statusMessage('File uploaded successfully');
      delete this.files[fileIndex].progress
    },
    uploadProgress(file, progress, bytesSent) {
      const fileIndex = this.findFileIndex(file.upload.uuid);
      this.statusMessage('File uploading...');
      this.files[fileIndex].progress = file.upload.progress
    },
    setFiles: function setFiles(files) {
      this.files = files;
    },
    focusElement: function focusElement(element){
      setTimeout(function(){ $(element).focus() }, 200);
    },
    statusMessage: function statusMessage(message) {
      $('#email-status').text(message);
    }
  }
}
</script>

<template>
  <div v-show="isVisible">
    <p>Files can be no larger than {{ maxsize }} MB per file. Allowed file types are: {{ accepted }}.</p>
    <vue-dropzone id="email_attachments_widget" ref="myVueDropzone" :options="dropzoneOptions" :use-custom-slot="true"
      @vdropzone-sending="sending"
      @vdropzone-success="success"
      @vdropzone-error="error"
      @vdropzone-upload-progress="uploadProgress"
      @vdropzone-removed-file="removedFile">
      <div tabindex=0 class="drop-file">
        <span aria-hidden=true>
        Drop file here to upload
        <br>
        or
        <br>
        </span>
        <span aria-hidden=true>Click to browse</span>
        <br>
        <span class="screen-reader-only">Press enter to browse</span>

      </div>
    </vue-dropzone>
    <email-attachments-preview v-for="preview in files" :key="preview.uuid" :preview="preview" :inputName="inputName"
      @remove-file="remove"></email-attachments-preview>
    <br>
  </div>
  <div v-show="!isVisible" class="buttons-wrapper">
    <a class='button' role='button' href='#' aria-label='Add email template attachment' @click="visible=true">Add Attachment</a>
  </div>
</template>
